.Countdown {
    height: 100vh;

    div:first-child {
        animation: smoothtransition 0.5s ease-in-out;
    }

    .barraMoche {
        width: 1424.04px;
        margin-left: -100px;
        margin-top: -35px;
        z-index: 999;
        position: relative;
    }

    .rightBarCount {
        position: fixed;
        height: 800px;
        right: 0%;
        bottom: 0%;
    }
    .textChangeAnim {
        animation: textChange 1s infinite;
    }
    .numbersCount {
        font-size: 350px;
        text-align: center;
        text-transform: uppercase;
        left: 50%;
        //animation: textChange 0.9s infinite;

        @keyframes textChange {
            0% {
                transform: scale(1);
                -webkit-font-smoothing: antialiased;
                opacity: 1;
            }

            25% {
                transform: scale(1);
            }
            50% {
                transform: scale(1);
            }
            75% {
                transform: scale(1);
            }
            100% {
                transform: scale(0);
            }
        }
    }
    .textCount {
        margin-top: 70px;
        font-size: 40px;
        text-align: center;
        width: 200px;
        text-transform: uppercase;
        margin-left: 540px;
        font-family: 'SpaceMono-Bold';
    }
    .crossCount img {
        position: fixed;
        width: 15%;
        top: 50%;
        left: 50%;
        min-width: 300px;
        transform: translate(-50%, -50%);
        z-index: 1000;
    }

    @keyframes smoothtransition {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
