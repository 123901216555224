.Game {
    height: 100vh;

    div:first-child {
        animation: smoothtransition 0.5s ease-in-out;
    }

    .barraMoche {
        width: 1424.04px;
        margin-left: -100px;
        margin-top: -35px;
        z-index: 999;
        position: relative;
    }

    .hidden {
        opacity: 0;
    }

    .hidden2 {
        opacity: 0;
    }

    .rightBarGame {
        position: fixed;
        height: 800px;
        right: 0%;
        bottom: 0%;
    }
    .textGame {
        font-size: 175px;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Cinderblock-100';
        animation: textChange 2s infinite;
        margin-top: 75px;
        margin-left: auto;

        @keyframes textChange {
            0% {
                transform: scale(0);
                -webkit-font-smoothing: antialiased;
                opacity: 1;
            }

            25% {
                transform: scale(1);
            }
            50% {
                transform: scale(1);
            }
            75% {
                transform: scale(1);
            }
            100% {
                transform: scale(0);
            }
        }
    }

    .crossGame img {
        position: fixed;
        width: 18.333%;
        top: 50%;
        left: 50%;
        min-width: 500px;
        transform: translate(-50%, -50%);
    }

    .showPoints {
        font-size: 40px;
        position: fixed;
        bottom: 12.5%;
        left: 0;
        right: 0;
        text-align: center;
        margin-left: auto;
        font-family: 'SpaceMono-Bold';
        text-transform: uppercase;
    }
    .countDown {
        font-size: 40px;
        position: fixed;
        top: 24%;
        left: 0;
        right: 0;
        text-align: center;
        margin-left: auto;
        font-family: 'SpaceMono-Bold';
        text-transform: uppercase;
    }

    @keyframes smoothtransition {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
