.Result {
    height: 100vh;
    background-color: #12171aff;
    opacity: 1;

    .barraMoche {
        width: 1424.04px;
        margin-left: -100px;
        margin-top: -35px;
        z-index: 999;
        position: relative;
    }

    .rightBarResult {
        position: fixed;
        height: 800px;
        right: 0%;
        bottom: 0%;
    }
    .numbersResult {
        font-size: 420px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 24%;
        text-transform: uppercase;

        .numbersResult:first-child {
            height: 1037px;
        }

        p:first-child {
            font-size: 70px;
            font-weight: 300;
            font-family: 'Cinderblock-100';
            letter-spacing: 10px;
            color: #ff5959ff;
            margin: -100px 0 0 13px;
        }
        p:nth-child(2) {
            font-size: 35px;
            font-family: 'SpaceMono-Bold';
            color: #ff5959ff;
            margin-top: 75px;
        }
    }

    .crossResult img {
        position: fixed;
        width: 22%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
