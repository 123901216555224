@font-face {
    font-family: "Cinderblock-100";//./fonts/Cinderblock-100.eot
    src: url("../fonts/Cinderblock-100/fonts/Cinderblock-100.eot"); /* IE9 Compat Modes */
    src: url("../fonts/Cinderblock-100/fonts/Cinderblock-100.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/Cinderblock-100/fonts/Cinderblock-100.otf") format("opentype"), /* Open Type Font */
      url("../fonts/Cinderblock-100/fonts/Cinderblock-100.svg") format("svg"), /* Legacy iOS */
      url("../fonts/Cinderblock-100/fonts/Cinderblock-100.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/Cinderblock-100/fonts/Cinderblock-100.woff") format("woff"), /* Modern Browsers */
      url("../fonts/Cinderblock-100/fonts/Cinderblock-100.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: "SpaceMono-Bold";
    src: url("../fonts/SpaceMono-Bold/fonts/SpaceMono-Bold.eot"); /* IE9 Compat Modes */
    src: url("../fonts/SpaceMono-Bold/fonts/SpaceMono-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/SpaceMono-Bold/fonts/SpaceMono-Bold.otf") format("opentype"), /* Open Type Font */
      url("../fonts/SpaceMono-Bold/fonts/SpaceMono-Bold.svg") format("svg"), /* Legacy iOS */
      url("../fonts/SpaceMono-Bold/fonts/SpaceMono-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/SpaceMono-Bold/fonts/SpaceMono-Bold.woff") format("woff"), /* Modern Browsers */
      url("../fonts/SpaceMono-Bold/fonts/SpaceMono-Bold.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SpaceMono-Regular";
    src: url("../fonts/SpaceMono-Regular/fonts/SpaceMono-Regular.eot"); /* IE9 Compat Modes */
    src: url("../fonts/SpaceMono-Regular/fonts/SpaceMono-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("../fonts/SpaceMono-Regular/fonts/SpaceMono-Regular.otf") format("opentype"), /* Open Type Font */
      url("../fonts/SpaceMono-Regular/fonts/SpaceMono-Regular.svg") format("svg"), /* Legacy iOS */
      url("../fonts/SpaceMono-Regular/fonts/SpaceMono-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
      url("../fonts/SpaceMono-Regular/fonts/SpaceMono-Regular.woff") format("woff"), /* Modern Browsers */
      url("../fonts/SpaceMono-Regular/fonts/SpaceMono-Regular.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}