.PreStart {
    background: #12171aff;
    height: 100vh;

    .flex-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    div {
        .mochTopLogo {
            width: 10%;
            text-align: right;
            right: 10%;
            position: fixed;
        }
    }

    .flex-box {
        div {
            font-size: 50px;
            text-align: center;

            p {
                font-family: 'SpaceMono-Bold';
                text-transform: uppercase;
            }

            .item1 {
                color: #ffdf80ff;
            }
            .item2 {
                color: #ccb3ffff;
                font-size: 50px;
            }
            .item3 {
                color: #ff5959ff;

                .mind-blown {
                    animation: change 1.5s alternate ease-in-out;
                    opacity: 0;
                }

                @keyframes change {
                    0% {
                        opacity: 1;
                    }
                    70% {
                        opacity: 1;
                    }
                    80% {
                        opacity: 0;
                    }
                }

                .confetti {
                    position: fixed;
                    left: 150%;
                    top: 50%;
                    bottom: 50%;
                    width: 250px;
                    height: 250px;
                }
            }
        }
    }
    /*.flex-box div p {
        animation: prestart 1s linear infinite;
        opacity: 1;
    }

    @keyframes prestart {
        0% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }*/
}
